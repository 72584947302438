import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import Layout from "../components/Layout"
import Grid from "../components/RecipeGrid"
import Seo from "../components/Seo"
import { CategoryPage, QueryResult, RecipePage } from "../models"

interface CategoryProps {
  location: Location
  pageContext: {
    id: string
  }
  data: CategoryQuery
}

interface CategoryQuery {
  result: QueryResult
  category: CategoryPage
}

const CategoryTemplate: FunctionComponent<CategoryProps> = ({ data }) => {
  const { result, category }: CategoryQuery = data
  const recipes: RecipePage[] = result.nodes.map((x) => x.node as RecipePage)
  const moreThanFour: boolean = recipes.length > 4

  return (
    <Layout showSearchbox={true} useH1Header={false}>
      <Seo metadata={category} />
      <h1>{category.name}</h1>
      <Grid recipes={recipes} hasBigCard={moreThanFour}></Grid>
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query categoryPage($id: String!, $locale: String!) {
    result: allContentfulRecipe(
      sort: { fields: publishDate, order: DESC }
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes: edges {
        node: node {
          featuredImage: thumbnail {
            ...CardImage
          }
          featuredImageLarge: thumbnail {
            ...CardImageLarge
          }
          ...RecipeMetadata
        }
      }
    }
    category: contentfulCategory(
      id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      ...CategoryMetadata
    }
  }
`
